<template>
  <referentiel-list
  :listTitle="'Liste des ethnies'"
  :referentielName="referentielName"
  :tableFields="tableFields"
  :emptyReferentielItem="emptyReferentielItem"
  >
  <template #formContent="{ handleInput, editableItem }">
    <div class="row">
      <div class="col-sm-12">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <div class="form-floating mb-3">
            <input :value="editableItem.nom" @input="handleInput" type="text" class="form-control" id="nom" name="nom" placeholder="Nom de la langue">
            <label for="nom">Nom</label>
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
          </div>
        </ValidationProvider>
      </div>
    </div>
  </template>

  </referentiel-list>
</template>

<script>
import ReferentielList from '../../../components/common/referentiel/ReferentielList.vue'
export default {
  components: {
    ReferentielList
  },
  computed: {
    referentielName () {
      return 'ethnie'
    },
    emptyReferentielItem () {
      return {
        nom: null
      }
    },
    tableFields () {
      return [
        '#',
        'index',
        { key: 'nom', label: 'Nom' },
        'actions',
      ]
    },
  }

}
</script>

<style>

</style>